import {GraphqlQueryTable} from "../GraphqlQueryTable.jsx";
import {DeleteButton} from "../common/components/DeleteButton.jsx";
import {ToastContainer} from "react-toastify";


export const INSTANCES_QUERY = `query {
        data:powertabsInstances {
           name
         }
    }`

export const DELETE_MUTATION = `mutation($name: String!) {
        deletePowertabsInstance(name: $name) {
            name
        }
    }`


const buttons = ([
    {
        Header: "Delete Instance",
        Cell: (props) => (<div>
            <DeleteButton
                deleteMutation={DELETE_MUTATION}
                apiParameters={{name: props.row.original.name}}
                filePrefixName={props.row.original.name}
            />
        </div>)
    }
 ])

export const INSTANCES_COLUMNS = () => [
    ... buttons,
    {
        Header: 'Instance Name',
        accessor: 'name'
    },
]

export const PowertabsInstances = () => {
    return (
        <div>
            <ToastContainer autoClose={false}/>
            <GraphqlQueryTable
                pageName="Powertabs Instances"
                generateColumns={INSTANCES_COLUMNS}
                query={INSTANCES_QUERY}
            />
        </div>
    )
}

